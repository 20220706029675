import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
  ErrorMessage,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const BusinessForm = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const interests = [
    {
        "id": "7961a4c0bb",
        "name": "Level 1"
    },
    {
        "id": "8b83af9712",
        "name": "Mastery"
    },
    {
        "id": "30f4b48196",
        "name": "Corporate"
    },
    {
        "id": "e43c4a5e38",
        "name": "Personal Consultation"
    },
    {
        "id": "8da686a466",
        "name": "Free Trial"
    },
    {
        "id": "c66760abc3",
        "name": "Contact form"
    }
  ]
  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }
    if (!values.company) {
      errors.company = "Required"
    }
    // if (!values.participants) {
    //   errors.participants = "Required"
    // }
    if (!values.emailAddress) {
      errors.emailAddress = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address"
    }

   
    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.kaarmibeing.com/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `%BE7ISvKz@jjFTnQNwr4Pxgn`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      company: "",
      participants: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        emailAddress,
        company,
        participants,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      axios.post("/.netlify/functions/formHandler" , {firstName , lastName ,emailAddress:emailAddress.toLowerCase() , company , participants , interest: interests[2]})
      .then(data => {
        console.log(data)
      })
      .catch(console.log)

      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("emailAddress", emailAddress)
      bodyFormData.set("company", company)
      bodyFormData.set("participants", participants)
      
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.kaarmibeing.com/wp-json/contact-form-7/v1/contact-forms/700/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/personal-consultation/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit}>
    <StyledFieldset>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="firstName">
          <StyledLabelHeader
            required
            isEmpty={errors.firstName && touched.firstName}
          >
            First Name
          </StyledLabelHeader>
          <StyledInput
            id="firstName"
            name="firstName"
            type="text"
            onChange={handleChange}
            value={values.firstName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.firstName && touched.firstName ? (
          <span className="error-message">{errors.firstName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="lastName">
          <StyledLabelHeader
            required
            isEmpty={errors.lastName && touched.lastName}
          >
            Last Name
          </StyledLabelHeader>
          <StyledInput
            id="lastName"
            name="lastName"
            type="text"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.lastName && touched.lastName ? (
          <span className="error-message">{errors.lastName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="emailAddress">
          <StyledLabelHeader
            required
            isEmpty={errors.emailAddress && touched.emailAddress}
          >
            Email Address
          </StyledLabelHeader>
          <StyledInput
            id="emailAddress"
            name="emailAddress"
            type="email"
            onChange={handleChange}
            value={values.emailAddress}
            
            onBlur={handleBlur("emailAddress")}
            required
          />
        </StyledLabel>
        {errors.emailAddress && touched.emailAddress ? (
          <span className="error-message">{errors.emailAddress}</span>
        ) : null}
      </StyledFormElementWrapper>
      
      <StyledFormElementWrapper>
          <StyledLabel htmlFor="company">
            <StyledLabelHeader
            required
            isEmpty={errors.company && touched.company}
          > 
            Company name</StyledLabelHeader>
            <StyledInput
              id="company"
              name="company"
              type="text"
              onChange={handleChange}
              value={values.company}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.company && touched.company ? (
          <span className="error-message">{errors.company}</span>
        ) : null}
        </StyledFormElementWrapper>
          
        <StyledSelectWrapper required>
          <StyledFormElementWrapper>
            <StyledLabelHeader
              required
              isEmpty={errors.participants && touched.participants}
            >
              Number of employees?
            </StyledLabelHeader>

            <StyledSelect
              defaultValue="default"
              name="participants"
              id="participants"
              onChange={handleChange}
              onBlur={handleBlur}
              required
            >
              <StyledOption value="default" disabled hidden>
                Select number of employees?
              </StyledOption>  
              <StyledOption value="SelfEmployed">Self Employed</StyledOption>
              <StyledOption value="1-10-employees">1-10 employees</StyledOption>
              <StyledOption value="11-50-employees">11-50 employees</StyledOption>
              <StyledOption value="51-200-employees">51-200 employees</StyledOption>
              <StyledOption value="201-500-employees">201-500 employees</StyledOption>
              <StyledOption value="501-1000-employees">501-1000 employees</StyledOption>
              <StyledOption value="1001-5000-employees">1001-5000 employees</StyledOption>
              <StyledOption value="5001-10000-employees">5001-10,000 employees</StyledOption>
              <StyledOption value="10001-employees">10,001+ employees</StyledOption>
            </StyledSelect>
            {errors.participants && touched.participants ? (
            <span className="error-message">{errors.participants}</span>
          ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>
        
    </StyledFieldset>
    <StyledButton
      type="submit"
      value="Send Message"
      disabled={isSubmitting || !isFormValid}
    //   onClick={() => analyticClickEvent("Demo Form Submitted")}
    >
      Submit
      </StyledButton>
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
  </StyledForm>
  )
}

export default BusinessForm
