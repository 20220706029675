import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BusinessForm from "../components/businessForm"
import Icon from "../components/icon"
import {
    Section,
    SectionHeading,
    SectionLede,
  } from "../styledComponents/section"
import { 
    ProgramSection, 
    ProgramOverview,
    ProgramBenefits,
    ProgramLectureHeader,
    ProgramLectureList,
    ProgramLectureListItem } from '../styledComponents/program'
import {
    TeamSection,
    TeamList,
    TeamListItem,
    TeamPhotoWrapper,
    TeamPosition,
    TeamBiography,
    } from "../styledComponents/team"
import { OverviewIntro, LearningBlock, BusinessJourneyBlock, BackgroundColourBlock } from "../styledComponents/overView"
import { StyledButton, StyledCloseModalButton } from "../styledComponents/button"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import {StyledModal } from "../styledComponents/modal"
import HeroBanner from "../components/HeroBanner"
import { HeroDescription, HeroText, HeroHeader } from "../styledComponents/heroBanner"  


function FancyModalButton() {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
  
    return (
      <>
        <StyledButton onClick={toggleModal}>Learn more</StyledButton>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          className="index-modal"
        >
         <StyledCloseModalButton onClick={toggleModal}>
           <Icon name="nav" />
          </StyledCloseModalButton>
          <div className="modal-content">
            <p>In today’s world there is a big gap between the lower threshold, which is "the minimum effort necessary to maintain a job" and the upper threshold of "optimal and maximum performance". This gap, which is based on the employee's "goodwill", is associated with the employee’s feeling of "belonging", "caring" and the feeling of "identifying with the goals and the corporate culture".</p>
            <p>These conclusions lead us to understand the necessity of being able to correctly analyse  the leading type of our employee’s personalities in order to have highly effective and indiviualised management strategies in place. This will increase an employee’s sense of “good will”, “caring” and “feeling of belonging”. All this has a direct effect on improving your corporate culture and achieving better productivity.</p>
            <h3>Among other topics we will learn why some employees are:</h3>
            <ul>
            <li>are natural “problem solvers' </li>
            <li>full of negativity while others are very argumentative or lazy</li>
            <li>why others are focused on past events or alternatively afraid of future failures</li>
            </ul>

            <p>We will understand how to manage all personality types which reduces mental stress and unnecessary conflicts  for the benefits of the company.</p>
            <p>The categorisation of these types of leading personalities is based on a 5000 year old ancient Asian philosophy, which was revealed to the west only 100 years ago.</p>
            <p>This philosophy gives us a new way to analyse our employees’ characteristics and a simple way of implementation. This course intends to give managers  new tools to achieve a better “corporate culture” and higher productivity.</p>

            <h3>Let’s look at an example of one personality type:</h3>
            <p>On one hand some of our employees are vivid and energetic. They always lead the action and are big activists. However, on the other hand they are also argumentative and it is always important for them to prove that they are right. Their leading emotion is anger and control. </p>
            <p>They should be positioned as managers, project managers or in any kind of job which requires initiating actions. They will prosper In any job with a lot of freedom to act. Despite these good qualities, it is not easy to manage them as they do not like to “follow orders”. As soon as you instruct them to do things in a particular way which might be different to their own way, they will become argumentative. By trying to force them harshly to follow instructions they will lose their sense of “good will”, “satisfaction” and will reduce their productivity.</p>
            <p>As these employees are very valuable to any organization and as we do not want to “clip their wings” and lose their feeling of "identifying with the corporate culture and its goals", we need to manage them smartly.</p>
            <p>Based on our philosophy, the way to manage them is absolutely not by ordering or instructing them but rather by asking for their assistance. </p>

            <p>Let’s look at an example; you instruct your employee who is a project manager to stop his project and to do some work for another department. He or she might argue or even refuse to do so by explaining to you that he/she is too busy. Instead of arguing you can ask this employee for help based on their expertise, which is essential for the other department. The person will very likely be happy to assist, as they like to feel needed and in control.  </p>
            <p>The session will combine academic research, results and data combined with this ancient asian philosophy.  This will help us to break the old paradigm and to adopt a way of thinking that is an “out of box” approach on the topics mentioned above. </p>
            </div>
        </StyledModal>
      </>
    );
  }

  function AmirModalButton(data) {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
  
    return (
      <>
        <StyledButton onClick={toggleModal}>Read more</StyledButton>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
        >
         <StyledCloseModalButton onClick={toggleModal}>
           <Icon name="nav" />
          </StyledCloseModalButton>
          {parse(data.data)}
        </StyledModal>
      </>
    );
  }
  
  const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
  `;

const Heading = ({ level = 1, location, children }) => (
    <SectionHeading as={`h${level}`} level={level} location={location}>
      {children}
    </SectionHeading>
  )
const Corporate = ({ data }) => {

  const team = data.allWpPage.nodes
  return (
    <>
    <Layout hasHero>
      <SEO title={data.wpPage.title} />

      <HeroBanner image={data.wpPage.heroSection.kaarmibeingHeroBackground.localFile.publicURL} backgroundPosition="-262px">
          <HeroText>
            <HeroHeader>
                {data.wpPage.heroSection.kaarmibeingHeroHeading}                
            </HeroHeader>
            <AnchorLink to="/corporate#overview" stripHash title="Find out how the KaarmiBeing corporate course can help you.">Find out more</AnchorLink>
          </HeroText>
      </HeroBanner>

      <ProgramSection id="overview">
        <ProgramOverview>
          {parse(data.wpPage.content)}
        </ProgramOverview>
      </ProgramSection>
        
        {data.wpPage.overviewBlocks.kaarmibeingLearningBlocks.map(
            (data, index) => {
            return (
            <>
                <BackgroundColourBlock number={index}>
                    <LearningBlock number={index}>
                        <figure>
                        <img src={data.image.localFile.publicURL} alt="" width="640" height="430" />
                        </figure>
                        <div className="overviewWrapper">
                            <h2>{data.heading}</h2>
                            {parse(data.lede)}

                        </div>               
                    </LearningBlock>
                </BackgroundColourBlock>
            </>
            )
        })}
            
        <BackgroundColourBlock number="3">
          <LearningBlock>
            <ProgramBenefits>
            <h2>{data.wpPage.businessLeaveWith.whatYouGetHeader}</h2>
            {parse(data.wpPage.businessLeaveWith.whatYouGetContent)}
            </ProgramBenefits>
          </LearningBlock>
        </BackgroundColourBlock>

        <BackgroundColourBlock number="4">
            <BusinessJourneyBlock number='4' >
                    <div className="wrapper">
                        <h2>Our journey will start by understanding that the good old cliché that "people work only for money and will be more productive after they receive even more money" – is wrong</h2>
                        <p>This is a very unexpected conclusion in the materialistic world in which we live. Naturally, money creates a higher productivity in the short term but it also creates long term dissociation. Therefore it is not only costly, it is also inefficient in the long run.</p>        
                        <ModalProvider backgroundComponent={FadingBackground}>
                                    <FancyModalButton />
                                </ModalProvider>
                    </div>               
            </BusinessJourneyBlock>
        </BackgroundColourBlock>

        <BackgroundColourBlock number="5">
            <LearningBlock number="5">
                <figure>
                <img src={data.wpPage.businessLeaveWith.whoIsThisForImage.localFile.publicURL} alt="" width="640" height="430" />
                </figure>
                <div className="overviewWrapper">
                    <h2>{data.wpPage.businessLeaveWith.whoIsThisForHeader}</h2>
                    {parse(data.wpPage.businessLeaveWith.whoIsThisForContent)}
                </div>               
            </LearningBlock>
        </BackgroundColourBlock>
       
       
        <BackgroundColourBlock number="5" style={{background: "var(--grey-lightest)"}}>
        <TeamSection id="our-leadership">
        <TeamList>
          {data.allWpPage.nodes[0].OurTeam.kaarmibeingTeamList.map(
            member => {

              const memberImage = {
                imageData: member.kaarmibeingTeamPhoto.localFile.childImageSharp.gatsbyImageData,
                alt: member.kaarmibeingTeamPhoto.altText || ``, 
              }

              return (
                <TeamListItem
                  key={member.kaarmibeingTeamPhoto.id}
                  type="business"
                >
                  <Heading level={3}>
                  The KaarmiBeing Management Mastery class is taught by {member.kaarmibeingTeamName}
                  </Heading>
                  <TeamPhotoWrapper>
                    <GatsbyImage image={memberImage.imageData} alt={memberImage.alt} />
                    <figcaption>
                      <TeamPosition>
                        {member.kaarmibeingTeamTitle}
                      </TeamPosition>
                      <TeamBiography>
                        {member.kaarmibeingTeamShortBio}
                      </TeamBiography>
                    </figcaption>
                  </TeamPhotoWrapper>
                  <ModalProvider backgroundComponent={FadingBackground}>
                      <AmirModalButton data={member.kaarmibeingTeamFullBio}/>
                  </ModalProvider>
                </TeamListItem>
              )
            }
          )}
        </TeamList>
      </TeamSection>
      </BackgroundColourBlock>

      <Section>
        <Heading level={1}>
          Enquiry
        </Heading>
        <SectionLede>
          For more information please contact us and we will be in touch.
        </SectionLede>
        <BusinessForm />
      </Section>
      
    </Layout>
    </>
  )
}

export default Corporate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "corporate" }) {
      title
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
              publicURL
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      overviewBlocks {
        fieldGroupName
        kaarmibeingLearningBlocks {
            heading
            lede
            image {
              localFile {
                publicURL
              }
              id
            }
            
        }
      }
      businessLeaveWith {
        whatYouGetContent
        whatYouGetHeader
        whoIsThisForContent
        whoIsThisForHeader
        whoIsThisForImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allWpPage(filter: {OurTeam: {kaarmibeingTeamList: {elemMatch: {kaarmibeingTeamName: {eq: "Amir Bush"}}}}}) {
    nodes {
        OurTeam {
        kaarmibeingTeamList {
          kaarmibeingTeamFullBio
          kaarmibeingTeamName
          kaarmibeingTeamShortBio
          kaarmibeingTeamTitle
          kaarmibeingTeamPhoto {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
  }
`
