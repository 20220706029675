import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { Section } from "../styledComponents/section"

const TeamSection = styled(Section)`
  max-width: 130.4rem;
  margin: 0 auto;
`

const TeamList = styled.ol`
  list-style-type: none;
  width: 100%;
  margin: 0;
  ${baseGridStyles}
  padding: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0;
  }

`
const TeamListItem = styled.li`
  text-align: center;
  position: relative;
  margin: 0;
  padding: 0;
  grid-column: span 4;
  padding-bottom: 1.6rem;
  
  &:nth-of-type(2) {
    display: ${props => (props.type == 'business' ? 'none' : 'block')};
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    &:nth-of-type(1) {
      grid-column-start: ${props => (props.type == 'business' ? '2' : '1')};
      grid-column-end: ${props => (props.type == 'business' ? '6' : '4')};
    }
    &:nth-of-type(2) {
      grid-column-start: ${props => (props.type == 'business' ? '2' : '4')};
      grid-column-end: ${props => (props.type == 'business' ? '6' : '7')};
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    &:nth-of-type(1) {
      
      grid-column-start: ${props => (props.type == 'business' ? '4' : '3')};
      grid-column-end: ${props => (props.type == 'business' ? '10' : '7')};
    }
    &:nth-of-type(2) {
      grid-column-start: ${props => (props.type == 'business' ? '5' : '7')};
      grid-column-end: ${props => (props.type == 'business' ? '9' : '11')};
    }
  }

  h3 {
    margin-bottom: 1.6rem;
    text-align: center;
  }
`

const TeamPhotoWrapper = styled.figure`
 
  img {
    width: 19.6rem;
    height: 19.6rem;
    border-radius: 50%;
  }
  a {
    width: 19.6rem;
    height: 19.6rem;
    display: block;
    border: 0;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    &:hover {
      border: 0;
    }
  }
  svg {
      position:absolute;
      bottom: 0;
      width: 2.4rem;
      height: 2.4rem;
    }
`

const TeamPosition = styled.h4`
  color: var(--grey-darker);
`

const TeamBiography = styled.p`
  margin: 0 0 1.6rem;
`

export {
    TeamSection,
    TeamList,
    TeamListItem,
    TeamPhotoWrapper,
    TeamPosition,
    TeamBiography,
  }